
export const GTMPhoneClick = () => {


  var options = {
    "event_category":"Phone",
    "event_label":"Phone Click"

  }



    //@ts-ignore
    window && window.gtag &&  window.gtag("event","phoneclick",options) ;
    // return pageEvent;

};


export const GTMMetaforaSubmit = () => {


  var options = {
    "event_category":"Form Submit",
    "event_label":"Metafora Form Submit"

  }



    //@ts-ignore
    window && window.gtag &&  window.gtag("event","metaforasubmit",options) ;
    // return pageEvent;

};

export const GTMContactSubmit = () => {


  var options = {
    "event_category":"Form Submit",
    "event_label":"Contact Form Submit"

  }



    //@ts-ignore
    window && window.gtag &&  window.gtag("event","contactsubmit",options) ;
    // return pageEvent;

};
