import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import React, {useState,useEffect} from "react"
import {GTMPhoneClick} from '../lib/gtag'


const Header = ({ siteTitle,setMobileMenu,mobilemenu }) => {

  const { pathname } = useLocation()



  useEffect(()=>{

    var lastScrollTop = 0;

    var main_header = document.getElementsByClassName("sticky-wrapper")[0];

    if(main_header){
      var main_header_height = main_header.offsetHeight
    }

    window.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....

      if(!document.getElementsByClassName("sticky-wrapper")[0]) return;

      if(main_header_height !== document.getElementsByClassName("sticky-wrapper")[0].offsetHeight){
        main_header_height = document.getElementsByClassName("sticky-wrapper")[0].offsetHeight
      }


      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop){



        main_header.style.top=`-${main_header_height}px`
        main_header.classList.remove("is-sticky")



      }
      else  {
        main_header.style.position="fixed"
        main_header.style.width="100%"

        main_header.style.top="0px"

        if(st !== 0){
          main_header.classList.add("is-sticky")

        }

        else {
          main_header.classList.remove("is-sticky")
          main_header.style.position="static"
        }





      }


      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, false);

  },[])
  return (
    <header>






      <div id="nav-bar" className="nav-bar-main-block absolute">
        <div style={{height:'81px'}} id="sticky-wrapper" className="sticky-wrapper">

      <div className="sticky-area">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div style={{position:'relative'}} className="responsive-menu-wrap">
              <div className="slicknav_menu">
                <a onClick={() => setMobileMenu(!mobilemenu)}  aria-haspopup="true" role="button" tabIndex={0} className="slicknav_btn slicknav_collapsed" style={{outline: 'none'}}>
                  <span className="slicknav_menutxt" />
                  <span className="slicknav_icon slicknav_no-text">
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" /></span></a>

    </div>
    </div>
            <div className="logocontainer mx-2">
              <div className="logo">
                <Link to="/"><img src="/images/logo.png" alt="logo" /></Link>
              </div>
              {/* Responsive Menu Area */}


            </div>
            <div className="menucontainer mx-2 d-lg-block d-none">
              <div className="navigation text-white theme-2">
                <div id="cssmenu">
                  <ul className="d-flex flex-nowrap">
                    {/* <li className={pathname === '/'? 'active':''}>

                    <Link to="/">ΑΡΧΙΚΗ</Link>

                    </li> */}

                        <li className={pathname === '/metakomisi'? 'active':''}>
                          <Link to="/metakomisi">ΜΕΤΑΚΟΜΙΣΗ</Link>
                        </li>

                        <li className={pathname === '/anabatorio'? 'active':''}>
                          <Link to="/anabatorio">ΑΝΑΒΑΤΟΡΙΟ</Link>
                        </li>
                        {/* <li><a href="#" title="Pages">Pages +</a>
                      <ul>
                        <li><a href="about.html" title="About">About</a></li>
                        <li><a href="team.html" title="Team">Team</a></li>
                        <li><a href="quotation.html" title="Quotation">Quotation</a></li>
                        <li><a href="faq.html" title="Faq">Faq</a></li>
                      </ul>
                    </li> */}

                        <li className={pathname === '/about'? 'active':''}>
                          <Link to="/about">ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ</Link>
                        </li>

                        <li className={pathname === '/contact'? 'active':''}>
                          <Link to="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="callcontainer mx-2 d-flex align-items-center">
                  <div className="navigation-btn_wd logo">
                    <a onClick={()=> GTMPhoneClick()}  href="tel:6949873177" className="btn btn-primary" title="get quotes">
                      <div className="d-flex align-items-center">
                        <i style={{fontSize:'1.5rem'}} className="las la-phone mr-0 mr-md-3"></i>
                        <span className="d-md-block d-none">694 987 3177</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </header>

  )


}







export default Header
