import React from 'react'
import {GTMPhoneClick} from '../lib/gtag'

const Footer = () => {
  return (

    <footer id="footer" className="footer-main-block">
  <div className="container">
    <div className="row text-white">
      <div className="col-lg-6 col-sm-6">
        <div className="about-widget footer-widget">
          <div className="logo">
          <img src="/images/logo.png" alt="logo" />
          </div>


        </div>
      </div>


      <div className="col-lg-6 col-sm-6">
        <div className="row">
          <div className="col-1">
            <div className="footer-icon">
              <i className="las la-phone" />
            </div>
          </div>
          <div className="col-11">
            <div className="footer-address">ΤΗΛΕΦΩΝΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</div>
            <div className="footer-address-dtl"><a onClick={()=> GTMPhoneClick()} href="tel:6949873177">694 987 3177</a>, <a onClick={()=> GTMPhoneClick()} href="tel:6942073329">694 207 3329</a></div>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <div className="footer-icon">
              <i className="las la-envelope" />
            </div>
          </div>
          <div className="col-11">
            <div className="footer-address">EMAIL</div>
            <div className="footer-address-dtl"><a href="mailto:johndimitropoulos2@gmail.com">johndimitropoulos2@gmail.com</a></div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div className="tiny-footer">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="copyright-block">
        <p>Copyright © {new Date().getFullYear()}. All Rights Reserved. Website by <a target="_blank" rel="noopener noreferrer" href="https://webdimension.gr"> WebDimension</a>
         </p>

          </div>
        </div>
        {/* <div className="col-md-6 text-right">
          <div className="copyright-social">
            <ul>
              <li className="policy"><a href="#" title="Privacy Policy">Privacy Policy </a></li>
              <li><a href="#" title="Terms & Conditions"> Terms &amp; Conditions </a></li>
              <li>
              </li><li className="dropdown">
                <a href="#" data-toggle="dropdown" title="English"><i className="las la-globe" />English<i className="las la-caret-square-down" /></a>
                <ul className="dropdown-menu">
                  <li><a href="#" title="French">French</a></li>
                  <li><a href="#" title="Germany">Germany</a></li>
                  <li><a href="#" title="Urdu">Bengali</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</footer>



  )
}

export default Footer
