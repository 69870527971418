/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState,useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from 'gatsby'
import Header from "./header"
import Footer from "./footer"
import { scaleDown as Menu } from 'react-burger-menu'
import Seo from './seo'



// import "./layout.css"

const Layout = ({ children,metainfo }) => {
 const [mobilemenu,setMobileMenu]=useState(false)

 // useEffect(() => {
 //   // Update the document title using the browser API
 //   document.querySelector('.preloader').classList.add('d-none')
 // },[]);



  return (

    <div id="outer-wrapper">

    {/* <div className="preloader">
		<div className="spinner">
			<div className="double-bounce1"></div>
			<div className="double-bounce2"></div>
		</div>
	</div> */}

      <Seo {...metainfo}/>
      <Menu disableAutoFocus onClose={() => setMobileMenu(false) } customBurgerIcon={ false } outerContainerId={ "outer-wrapper" } pageWrapId={ "page-wrapper" }  isOpen={mobilemenu}>
         <Link className="menu-item my-2" to="/">ΑΡΧΙΚΗ</Link>
         <Link className="menu-item my-2" to="/metakomisi">ΜΕΤΑΚΟΜΙΣΗ</Link>
         <Link className="menu-item my-2" to="/anabatorio">ΑΝΑΒΑΤΟΡΙΟ</Link>


         <Link className="menu-item my-2" to="/about">ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ</Link>
         <Link className="menu-item my-2" to="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</Link>




       </Menu>




     <div id="page-wrapper">
      <Header mobilemenu={mobilemenu} setMobileMenu={setMobileMenu} siteTitle={`Title`} />


        <main>{children}</main>
      <Footer/>
      </div>
      </div>


  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
